.hengel-eu {
  .ui.primary.button {
    background-color: #0c3188;
  }
  
  .ui.primary.button:active, .ui.primary.buttons .button:active {
    background-color: #041C52;
  }
  
  .ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: #041C52;
  }
}
