/* ==========================================================================
Header
========================================================================== */
.header-navigation {
  background: rgba(111, 111, 111, 0.75) !important;
}

img.logo {
  max-height: 45px;
  max-width: 50vw;
}

.avatar {
  min-height: 35px;
  max-height: 35px;
  border-radius: 0.5rem;
}

@media (min-width: 440px) {
  img.logo {
    max-height: 50px;
  }
}
