/* ==========================================================================
 General
 ========================================================================== */
.link {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.label-alarms-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-alarms-container .label-information {
  position: absolute;
  background-color: red;
  z-index: 10;
  top: 0.8em;
  margin: 0 0 0 1.3em !important;
  font-size: 0.6rem;
  min-width: 2em;
  min-height: 2em;
  padding: 0.4em !important;
  line-height: 1.3em;
  text-align: center;
  border-radius: 450rem;
}

/* ==========================================================================
 Devices overview list
 ========================================================================== */


.devices-overview-list .device {
  border-top: 1px solid #d4d4d5;
 
}

/* Label: (new)
========================================================================== */
.devices-overview-list .device .label-device {
  position: absolute;
  top: 5px;
  right: 70px;
  display: inline-block;
  line-height: 1;
  background-color: #008d2b;
  color: #fff;
  padding: 0.5833em 0.833em;
  border-radius: 0.28571429rem;
  font-size: 12px;
}

.devices-overview-list .device .label-device.mobil {
  right: 45px !important;
}

.devices-overview-list .device .label-device.already_added {
  background-color: #ffac29;
  color: #fff;
}
.devices-overview-list .device .label-device.already_added:after {
  border-left-color: #ffac29;
}

.devices-overview-list .device .label-device:after {
  left: 96%;
  top: 30%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #008d2b;
  border-width: 5px;
  margin-top: -4px;
}

/* Status devices
========================================================================== */
/* No cloud connectivity  status ok*/
.devices-overview-list .device.no_cloud_connectivity.ok {
  color: rgba(0, 0, 0, 0.6) !important;
}

/* No cloud connectivity state: alert = black */
.devices-overview-list .device.no_cloud_connectivity.alert {
  background-color: #000000 !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert button,
.devices-overview-list
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .inactive-button {
  border-color: #000000 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert button:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.devices-overview-list
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .icon-status {
  background-color: #000000 !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert .description {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert .link {
  color: rgba(255, 255, 255, 0.9) !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert .link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

/* No cloud connectivity  status alert incident inprogress */
.devices-overview-list .device.no_cloud_connectivity.alert_inprogress {
  background-color: #ffcc33 !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert_inprogress button,
.devices-overview-list
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .inactive-button {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-list
  .device.no_cloud_connectivity.alert_inprogress
  button:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.devices-overview-list
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .icon-status {
  background-color: #ffcc33 !important;
}

.devices-overview-list
  .device.no_cloud_connectivity.alert_inprogress
  .description {
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-list .device.no_cloud_connectivity.alert_inprogress .link {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-list
  .device.no_cloud_connectivity.alert_inprogress
  .link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* cloud connectivity state: alert = red  */
.devices-overview-list .device.cloud_connectivity.alert {
  background-color: #bd0000 !important;
}

.devices-overview-list .device.cloud_connectivity.alert button,
.devices-overview-list
  .device.cloud_connectivity.alert
  .toolbar-buttons
  .inactive-button {
  border-color: #bd0000;
  color: rgba(255, 255, 255, 0.9);
}

.devices-overview-list .device.cloud_connectivity.alert button:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.devices-overview-list
  .device.cloud_connectivity.alert
  .toolbar-buttons
  .icon-status {
  background-color: #bd0000 !important;
}

.devices-overview-list .device.cloud_connectivity.alert .description {
  background-color: #bd0000 !important;
}

.devices-overview-list .device.cloud_connectivity.alert .link {
  color: rgba(255, 255, 255, 0.9) !important;
}

.devices-overview-list .device.cloud_connectivity.alert .link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

/* cloud connectivity state: alert_inprogress   */
.devices-overview-list .device.cloud_connectivity.alert_inprogress {
  background-color: #ffcc33 !important;
}

.devices-overview-list .device.cloud_connectivity.alert_inprogress button,
.devices-overview-list
  .device.cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .inactive-button {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-list
  .device.cloud_connectivity.alert_inprogress
  button:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.devices-overview-list
  .device.cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .icon-status {
  background-color: #ffcc33 !important;
}

.devices-overview-list
  .device.cloud_connectivity.alert_inprogress
  .description {
  background-color: #ffcc33 !important;
}

.devices-overview-list .device.cloud_connectivity.alert_inprogress .link {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-list .device.cloud_connectivity.alert_inprogress .link:hover {
  color: rgba(0, 0, 0, 8) !important;
}

/* ==========================================================================
 Devices overview box
 ========================================================================== */
.devices-overview-grid {
  justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 5px !important;
}

/* unfortunately when the cards begin to show vertically (3 horizontal and #
vertical) the cards don't keep the same height (bug), for this reason at this
moment we have to define a fixed valued for the hight*/
.devices-overview-grid .device .content.image-group {
  height: 260px;
  display: flex;
}

.devices-overview-grid .device .image {
  max-width: 100%;
  max-height: 230px;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
  object-fit: contain;
}

.devices-overview-grid .device .toolbar-buttons {
  background-color: #e0e1e2 !important;
  padding: 8px !important;
  margin: 0 !important;
  flex-grow: 0 !important;
}

.devices-overview-grid .device .toolbar-buttons .inactive-button {
  background: default !important;
}

.devices-overview-grid .device .toolbar-buttons .inactive-button:hover {
  cursor: auto !important;
  background-color: #e0e1e2 !important;
}

.devices-overview-grid .device .content.description {
  flex-grow: 0 !important;
}

.devices-overview-grid .device .ui.icon.button {
  padding: 0.78571429em 0.5em 0.78571429em !important;
}

/* Status devices
 ========================================================================== */

/* No cloud connectivity state: alert = black  - no connectivity = opacity 50%*/
.devices-overview-grid .device.no_cloud_connectivity.alert {
  border: 0.9px solid #000000 !important;
}

.devices-overview-grid .device.no_cloud_connectivity.alert .link {
  color: rgba(255, 255, 255, 0.8) !important;
}

.devices-overview-grid .device.no_cloud_connectivity.alert .link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.devices-overview-grid .device.no_cloud_connectivity.alert .description {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.devices-overview-grid .device.no_cloud_connectivity.alert .toolbar-buttons,
.devices-overview-grid
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .disabled.button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .button.inactive-button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .button.inactive-button:hover {
  background-color: #eaebec !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.alert
  .toolbar-buttons
  .button:hover {
  background-color: #cacbcd !important;
}

/* No cloud connectivity state: alert_inprogress = yellow  - no connectivity = opacity 50%*/
.devices-overview-grid .device.no_cloud_connectivity.alert_inprogress {
  border: 0.9px solid #000000 !important;
}

.devices-overview-grid .device.no_cloud_connectivity.alert_inprogress .link {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .description {
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons,
.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .disabled.button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .button.inactive-button,
.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .button.inactive-button:hover {
  background-color: #ffcc33 !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.alert_inprogress
  .toolbar-buttons
  .button:hover {
  background-color: #ffcc33 !important;
}

/* No cloud connectivity state: ok = gray no connectivity = opacity 50% */
.devices-overview-grid .device.no_cloud_connectivity.ok {
  box-shadow: 0 1px 3px 0 #eaebec, 0 0 0 1px #eaebec !important;
}

.devices-overview-grid .device.no_cloud_connectivity.ok .description {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid .device.no_cloud_connectivity.ok .toolbar-buttons,
.devices-overview-grid
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .disabled.button,
.devices-overview-grid
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button,
.devices-overview-grid
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button.inactive-button,
.devices-overview-grid
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button.inactive-button:hover {
  background-color: #eaebec !important;
}

.devices-overview-grid
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button:hover {
  background-color: #cacbcd !important;
}

/* cloud connectivity state: alert */
.devices-overview-grid .device.cloud_connectivity.alert {
  border: 0.9px solid #bd0000 !important;
}

.devices-overview-grid .device.cloud_connectivity.alert .link {
  color: rgba(255, 255, 255, 0.8) !important;
}

.devices-overview-grid .device.cloud_connectivity.alert .link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.devices-overview-grid .device.cloud_connectivity.alert .description {
  background-color: #bd0000 !important;
}

/* cloud connectivity state: alert_inprogress */
.devices-overview-grid .device.cloud_connectivity.alert_inprogress {
  border: 0.9px solid #ffcc33 !important;
}

.devices-overview-grid .device.cloud_connectivity.alert_inprogress .link {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid .device.cloud_connectivity.alert_inprogress .link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.devices-overview-grid
  .device.cloud_connectivity.alert_inprogress
  .description {
  background-color: #ffcc33 !important;
}

/* Label: (new)
========================================================================== */
.devices-overview-grid .device .label-device {
  position: absolute;
  top: -29px;
  right: 0px;
  display: inline-block;
  line-height: 1;
  background-color: #008d2b;
  color: #fff;
  padding: 0.5833em 0.833em;
  border-radius: 0.28571429rem;
  font-size: 12px;
  z-index: 2;
}

.devices-overview-grid .device .label-device:after {
  top: 72%;
  right: 0%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #008d2b;
  border-width: 12px;
}

.devices-overview-grid .device .label-device.already_added {
  background-color: #ffac29;
  color: #fff;
}
.devices-overview-grid .device .label-device.already_added:after {
  border-top-color: #ffac29;
}
/* ==========================================================================
 Devices overview grid small
 ========================================================================== */
.devices-overview-grid-small {
  justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 5px !important;
}

.devices-overview-grid-small .device {
  width: 280px !important;
}

.devices-overview-grid-small .device .toolbar-buttons {
  background-color: #e0e1e2 !important;
  padding: 8px !important;
  margin: 0 !important;
  flex-grow: 0 !important;
}

.devices-overview-grid-small .device .toolbar-buttons .inactive-button {
  background: default !important;
}

.devices-overview-grid-small .device .toolbar-buttons .inactive-button:hover {
  cursor: auto !important;
  background-color: #e0e1e2 !important;
}

.devices-overview-grid-small .device .content.description {
  flex-grow: 0 !important;
  font-weight: bold;
}

.devices-overview-grid-small .device .ui.icon.button {
  padding: 0.78571429em 0.5em 0.78571429em !important;
}

/* Status devices
 ========================================================================== */

/* No cloud connectivity state: alert = black  - no connectivity = opacity 50%*/
.devices-overview-grid-small .device.no_cloud_connectivity.alert {
  border: 0.9px solid #000000 !important;
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

/* No cloud connectivity state: alert_inprogress = yellow  - no connectivity = opacity 50%*/
.devices-overview-grid-small .device.no_cloud_connectivity.alert_inprogress {
  border: 0.9px solid #ffcc33 !important;
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

/* No cloud connectivity state: ok = gray no connectivity = opacity 50% */
.devices-overview-grid-small .device.no_cloud_connectivity.ok {
  box-shadow: 0 1px 3px 0 #eaebec, 0 0 0 1px #eaebec !important;
}

.devices-overview-grid-small .device.no_cloud_connectivity.ok .description {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid-small .device.no_cloud_connectivity.ok .toolbar-buttons,
.devices-overview-grid-small
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .disabled.button,
.devices-overview-grid-small
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button,
.devices-overview-grid-small
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button.inactive-button,
.devices-overview-grid-small
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button.inactive-button:hover {
  background-color: #eaebec !important;
}

.devices-overview-grid-small
  .device.no_cloud_connectivity.ok
  .toolbar-buttons
  .button:hover {
  background-color: #cacbcd !important;
}

/* cloud connectivity state: alert */
.devices-overview-grid-small .device.cloud_connectivity.alert {
  border: 0.9px solid #bd0000 !important;
  background-color: #bd0000 !important;
}

.devices-overview-grid-small .device.cloud_connectivity.alert .link {
  color: rgba(255, 255, 255, 0.8) !important;
}

.devices-overview-grid-small .device.cloud_connectivity.alert .link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

/* cloud connectivity state: alert_inprogress */
.devices-overview-grid-small .device.cloud_connectivity.alert_inprogress {
  border: 0.9px solid #ffcc33 !important;
  background-color: #ffcc33 !important;
}

.devices-overview-grid-small .device.cloud_connectivity.alert_inprogress .link {
  color: rgba(0, 0, 0, 0.6) !important;
}

.devices-overview-grid-small
  .device.cloud_connectivity.alert_inprogress
  .link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* Label: (new)
========================================================================== */
.devices-overview-grid-small .device .label-device {
  position: absolute;
  top: -29px;
  right: 0px;
  display: inline-block;
  line-height: 1;
  background-color: #008d2b;
  color: #fff;
  padding: 0.5833em 0.833em;
  border-radius: 0.28571429rem;
  font-size: 12px;
  z-index: 2;
}

.devices-overview-grid-small .device .label-device:after {
  right: 0%;
  top: 72%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #008d2b;
  border-width: 12px;
  margin-left: -32px;
}

.devices-overview-grid-small .device .label-device.already_added {
  background-color: #ffac29;
  color: #fff;
}
.devices-overview-grid-small .device .label-device.already_added:after {
  border-top-color: #ffac29;
}
