/* ==========================================================================
Landing page layout

*****************************************************************************
* div = Landing-page                                                        *
*   *********************************************************************   *
*   * div = container                                                   *   *
*   *   *************************************************************   *   *
*   *   * div = background-images                                   *   *   *
*   *   *                                                           *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *  * div = header-navigation                            *   *   *   *
*   *   *  *                                                    *   *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *                                                           *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *  * div = images gallery                               *   *   *   *
*   *   *  *                                                    *   *   *   *
*   *   *  ******************************************************   *   *   *
*   *   *************************************************************   *   *
*   *                                                                   *   *
*   *   *************************************************************   *   *
*   *   *    div = information                                      *   *   *
*   *   *                                                           *   *   *
*   *   *************************************************************   *   *
*   *********************************************************************   *
*                                                                           *
*   *********************************************************************   *
*   * div = footer                                                      *   *
*   *********************************************************************   *
*                                                                           *
========================================================================== */

// Is a lot work done, so I prefer copy all stylesheets and ignore landing-page
// changing class name to landing-page-alan-nuttall-flexeserve-eu

.alan-nuttall-flexeserve-eu {
  .landing-page-alan-nuttall-flexeserve-eu {
    display: block;
    flex-direction: column;
    height: 100%;
    grid-template-rows: 1fr auto;
  }

  .landing-page-alan-nuttall-flexeserve-eu .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }

  .landing-page-alan-nuttall-flexeserve-eu .container .background-images {
    align-self: stretch;
    width: 100%;
    flex: 1;
  }

  .landing-page-alan-nuttall-flexeserve-eu
    .container
    .background-images
    .image-gallery {
    height: 100%;
  }

  .landing-page-alan-nuttall-flexeserve-eu
    .container
    .background-images
    .image-gallery
    .image-gallery-content {
    height: 100%;
  }

  .landing-page-alan-nuttall-flexeserve-eu
    .container
    .background-images
    .image-gallery
    .image-gallery-content
    .image-gallery-slide-wrapper {
    height: 100%;
  }

  .landing-page-alan-nuttall-flexeserve-eu
    .container
    .background-images
    .image-gallery
    .image-gallery-content
    .image-gallery-slide-wrapper
    img {
    object-fit: cover;
    z-index: -1;
  }

  @media (max-width: 640px) and (orientation: portrait) {
    .landing-page-alan-nuttall-flexeserve-eu
      .container
      .background-images
      .image-gallery
      .image-gallery-content
      .image-gallery-slide-wrapper
      img {
      object-fit: cover;
      height: calc(100vh - 183px);
      z-index: -1;
    }
  }

  .landing-page-alan-nuttall-flexeserve-eu
    .container
    .background-images
    .header-navigation {
    /* we should add position absolute if we want that the header scroll..
          I will prefer that the header is always fix: the option login and the logo
          if always visible*/
    position: absolute !important;
    z-index: 2;
  }

  .landing-page-alan-nuttall-flexeserve-eu .container .information {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0px;
  }

  @media (max-width: 640px) and (orientation: portrait) {
    .landing-page-alan-nuttall-flexeserve-eu .container .information {
      top: 36%;
    }
  }

  @media (max-width: 1024px) and (max-height: 1024px) and (orientation: portrait) {
    .landing-page-alan-nuttall-flexeserve-eu .container .information {
      top: 24%;
    }
  }

  @media (min-width: 440px) and (max-height: 620px) and (orientation: portrait) {
    .landing-page-alan-nuttall-flexeserve-eu .container .information {
      margin-top: -10%;
    }
  }

  @media (max-width: 1024px) and (min-height: 1025px) and (orientation: portrait) {
    .landing-page-alan-nuttall-flexeserve-eu .container .information {
      top: 40%;
    }
  }

  @media (orientation: landscape) {
    .landing-page-alan-nuttall-flexeserve-eu .container .information {
      top: 40%;
    }
  }

  .landing-page-alan-nuttall-flexeserve-eu .fleet-manager,
  .landing-page-alan-nuttall-flexeserve-eu .recipes,
  .landing-page-alan-nuttall-flexeserve-eu .contact {
    color: white !important;
    padding-top: 10%;
    padding-bottom: 10%px;
    border-radius: 0.5rem;
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -10%;
  }

  .landing-page-alan-nuttall-flexeserve-eu .fleet-manager a,
  .landing-page-alan-nuttall-flexeserve-eu .recipes a,
  .landing-page-alan-nuttall-flexeserve-eu .contact a {
    color: white;
  }

  .landing-page-alan-nuttall-flexeserve-eu .recipes {
    background-color: #2a6bb7 !important;
  }

  .landing-page-alan-nuttall-flexeserve-eu .fleet-manager .img {
    fill: #1fc26b !important;
  }

  .landing-page-alan-nuttall-flexeserve-eu .fleet-manager:hover,
  .landing-page-alan-nuttall-flexeserve-eu .recipes:hover,
  .landing-page-alan-nuttall-flexeserve-eu .contact:hover {
    cursor: pointer;
  }
}
