/* ==========================================================================
Body
========================================================================== */
html,
body,
.main-container {
  min-width: 100%;
  max-height: 100%;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-container > div {
  /* FIX elevation header, because withSnackbar add a new div */
  z-index: 1;
}

div#container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 16px;
  overflow-y: auto;
}
.footer-navigation {
  padding-top: 16px;
}

div#container > main {
  flex-grow: 1;
}

#root, #root > div  {
  width: 100%;
  height: 100%;
}

/* ==========================================================================
Header
========================================================================== */

/* ==========================================================================
Navigation
========================================================================== */

/* ==========================================================================
Media
========================================================================== */
@media (min-width: 767px) {
  .commander-navigation {
    margin-top: 20px;
  }

  .commander-edit-button {
    padding-right: 15px !important;
  }
}

/* ==========================================================================
Bug with modal/dialog box in iOS devices
========================================================================== */
.modal-ios {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
/* ==========================================================================
General
========================================================================== */
.App-logo {
  vertical-align: bottom;
  height: 40px;
}

hr {
  margin-top: 30px;
}

td {
  padding: 0px;
}

.row-increased-margin-top {
  margin-top: 20px;
}

.checkbox {
  padding-left: 1px;
}

.iframe {
  position: relative !important;
}

/* ==========================================================================
Messages & Alerts
========================================================================== */
.confirm-failed-notice {
  color: red;
  text-align: center;
}

.field .form-control-feedback-cloud {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  pointer-events: none;
}
.fields .field .form-control-feedback-cloud {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
}

.ui.form .field {
  flex: 0 1 auto;
}
/* formular by mobile devices - validation icon*/
.formular-mobile .form-control-feedback-cloud {
  line-height: 68px;
}

.invalid {
  color: red;
}

.close {
  align: right;
}

.valid {
  color: green;
}

.activation-message {
  margin-top: 20px;
}
.alert-danger {
  background-image: linear-gradient(to bottom, #ff554d 0, #ff4136 100%);
  border-color: #ff554d;
}

.alert-warning {
  background-image: linear-gradient(to bottom, #ff851b 0, #ffa04d 100%);
  border-color: #ff851b;
}

.alert-info {
  background-image: linear-gradient(to bottom, #eaeaea 0, #f2f2f2 100%);
  color: black;
  border-color: #eaeaea;
}

.warning-link {
  color: #1a66ff;
  text-decoration: underline;
}

/* ==========================================================================
Commander
========================================================================== */
.commander-edit-button {
  margin-right: 15px;
  padding-right: 0px;
}

.commander-navigation {
  padding: 0px;
}

/* ==========================================================================
Charts
========================================================================== */

/* ==========================================================================
Progressbar (react-progressbar)
The progressbar contains to class. an external class named progressbar-container
and a internal class named progressbar-progress. the internal class decides the
color of the progress bar.
========================================================================== */
.progressbar-progress {
  background-color: #265a88 !important;
  border-radius: 4px;
}
