.map-devices {
  margin-top: 1rem;
}

.mapboxgl-popup-content {
  font-size: 12px;
  background-color: white;
  padding: 0px !important;
  border-radius: 10px !important;
}

/* Marker **************************************************************/
.marker-icon {
  height: 26px;
}

/* POPUP DEVICE
***********************************************************************/
.map-devices .wrap {
}

.popup-map-device {
  display: grid;
  border-radius: 10px;
}

.popup-map-device .icons-bar {
  background-color: #e0e1e2;
  padding: 0.5rem;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: flex-end;
  align-content: space-around;
}

.popup-map-device .icons-bar .icon {
  max-height: 25px;
  max-width: 30px;
  margin-left: 1rem;
}

.popup-map-device .content {
  display: flex;
}

.popup-map-device a {
  color: black;
}

.popup-map-device .content div {
  padding: 0.5rem;
}

.popup-map-device .content .icon-device {
  width: 40px;
}

/* ALERTS **********************************************************************/
.popup-map-device.alert {
  background-color: #bd0000 !important;
}

.popup-map-device.alert .content {
  color: white;
}

.popup-map-device.alert .icons-bar {
  border: 1px solid #bd0000;
}
