.alan-nuttall-flexeserve-eu {
  .ui.primary.button,
  .ui.primary.buttons .button {
    background-color: #d94d15 !important;
  }
  @media (min-width: 440px) {
    img.logo {
      max-height: 64px;
    }
  }  
}
