.unread-icon {
  color: rgba(0, 0, 0, 0.2) !important;
}

.read-marker {
  cursor: pointer;
}

.container-button-mark-all-read {
  padding: 0 0 1rem 0;
}

.notification-box{
  padding-left: 8px;
  padding-right: 8px;
}