/* ==========================================================================
 group users list
 ========================================================================== */
.groups-users-list .toolbar-buttons .buttons button {
  background-color: white !important;
}

.groups-users-list .sort {
  box-shadow: none !important;
}

.groups-users-list .sort:focus,
.groups-users-list .button.sort:active {
  background: none !important;
}

.groups-users-list .user {
  border-bottom: 1px solid #d4d4d5;
  color: #d4d4d5;
}

.groups-users-list .bottons {
  border-top: 1px solid #d4d4d5;
}
