// [0-sm]
.ControlUnitDetailPage-container {
  margin-top: 1em;
  display: grid;
  grid-template-areas:
    'main-sensor'
    'set-points'
    'sensors'
    'status'
    'actions';
  grid-template-rows: auto;
  grid-template-columns: 100%;
  gap: 1em;
  .ControlUnit-Sensors-container {
    grid-area: sensors;
    .ui.cards > .card {
      width: 100%;
    }
  }
  .ControlUnit-SetPoints-container {
    grid-area: set-points;
  }
  .ControlUnit-MainSensor-container {
    grid-area: main-sensor;
  }
  .ControlUnit-Status-container {
    grid-area: status;
  }
  .ControlUnit-Actions-container {
    grid-area: actions;
  }
  .box-main-sensor .value-unit .value {
    padding: 0 !important;
  }
}
// [sm - md]
@media only screen and (min-width: 600px) {
  .ControlUnitDetailPage-container {
    margin-top: 1em;
    display: grid;
    grid-template-areas:
      'sensors main-sensor  main-sensor'
      'sensors status       set-points'
      'actions actions      actions';
    grid-template-rows: 270px auto auto;
    grid-template-columns: minmax(150px, 250px) 1fr 1fr;
    gap: 1em;
  }
}
// [md-xl]
@media only screen and (min-width: 900px) {
  .ControlUnitDetailPage-container {
    margin-top: 1em;
    display: grid;
    grid-template-areas:
      'sensors main-sensor  main-sensor  actions'
      'sensors status       set-points   actions';
    grid-template-rows: minmax(310px, min-content) auto;
    grid-template-columns: minmax(150px, 250px) 1fr 1fr minmax(100px, 246px);
    gap: 1em;
  }
}
