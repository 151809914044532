/* ==========================================================================
 General
 ========================================================================== */
.link {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.groups-overview-list .no-border,
.groups-overview-grid .no-border {
  box-shadow: none !important;
}

.GroupsOverview .name{
  padding-top: 16px;
  padding-bottom: 16px;
}

/* ==========================================================================
 Groups overview list
 ========================================================================== */
.groups-overview-list .name {
  overflow-wrap: break-word;
}

.groups-overview-list .group {
  border-top: 1px solid #d4d4d5;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.groups-overview-list .group .image {
  max-height: 35px;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}

.groups-overview-list .group .image.icon-devices img {
  max-height: 25px !important;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}

.groups-overview-list .group .image.icon-devices .label-quantity-device {
  background-color: #2a6bb7 !important;
}

.groups-overview-list .group .column-group-icon {
  text-align: center;
}

@media (max-width: 375px) {
  .groups-overview-list .group .column-group-icon {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.groups-overview-list .group .toolbar-buttons {
  padding-right: 0px !important;
}

/* Status Grouos
 ========================================================================== */

/* state: alert = red */
.groups-overview-list .group.alert {
  background-color: #bd0000 !important;
}

.groups-overview-list .group.alert button {
  border-color: #000000 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.groups-overview-list .group.alert .description {
  background-color: #bd0000 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.groups-overview-list .group.alert .link,
.groups-overview-list .group.alert .link a {
  color: rgba(255, 255, 255, 0.9) !important;
}

.groups-overview-list .group.alert .link:hover,
.groups-overview-list .group.alert .link a:hover {
  color: rgba(255, 255, 255, 1) !important;
}

/* state: alert_inprogress = yellow */
.groups-overview-list .group.alert_inprogress {
  background-color: #ffcc33 !important;
}

.groups-overview-list .group.alert_inprogress button {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-list .group.alert_inprogress .description {
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-list .group.alert_inprogress .link,
.groups-overview-list .group.alert_inprogress .link a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-list .group.alert_inprogress .link:hover,
.groups-overview-list .group.alert_inprogress .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.groups-overview-list .group.ok .link,
.groups-overview-list .group.ok .link a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-list .group.ok .link:hover,
.groups-overview-list .group.ok .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* ==========================================================================
 groups overview box
 ========================================================================== */
.groups-overview-grid {
  justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 5px !important;
}

.groups-overview-grid .name {
  overflow-wrap: break-word;
}

/* unfortunately when the cards begin to show vertically (3 horizontal and #
vertical) the cards don't keep the same height (bug), for this reason at this
moment we have to define a fixed valued for the hight*/
.groups-overview-grid .group .content.image-group {
  height: 260px;
  display: flex;
}

.groups-overview-grid .group .image {
  max-width: 100%;
  max-height: 230px;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
  object-fit: contain;
}

.groups-overview-grid .group .image.icon-devices img {
  max-height: 25px !important;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}

.groups-overview-grid .group .image.icon-devices .label-quantity-device {
  background-color: #2a6bb7 !important;
}

.groups-overview-grid .group .content.description {
  flex-grow: 0 !important;
  min-height: 94px;
  font-weight: bold;
}

.groups-overview-grid .group .content.description .contact-list {
  font-weight: normal;
  padding-right: 5px;
}

.groups-overview-grid .group .toolbar-buttons {
  background-color: #e0e1e2 !important;
  padding: 8px !important;
  margin: 0 !important;
  flex-grow: 0 !important;
}

.groups-overview-grid .group .toolbar-buttons .inactive-button {
  background: default !important;
}

/* Status Groups
 ========================================================================== */

/* state: alert = red */
.groups-overview-grid .group.alert {
  border: 0.9px solid #bd0000 !important;
}

.groups-overview-grid .group.alert .link,
.groups-overview-grid .group.alert .link a {
  color: rgba(255, 255, 255, 0.8) !important;
}

.groups-overview-grid .group.alert .link:hover,
.groups-overview-grid .group.alert .link a:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.groups-overview-grid .group.alert .description {
  background-color: #bd0000 !important;
  color: white !important;
}

/* state: alert_inprogress = yellow */
.groups-overview-grid .group.alert_inprogress {
  border: 0.9px solid #ffcc33 !important;
}

.groups-overview-grid .group.alert_inprogress .link,
.groups-overview-grid .group.alert_inprogress .link a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-grid .group.alert_inprogress .link:hover,
.groups-overview-grid .group.alert_inprogress .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.groups-overview-grid .group.alert_inprogress .description {
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

/* state: ok */
.groups-overview-grid .group.ok .content.description .link a {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-grid .group.ok .content.description .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* ==========================================================================
 Groups overview small grid
 ========================================================================== */
.groups-overview-grid-small {
  justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 2px !important;
}

.groups-overview-grid-small .name {
  overflow-wrap: break-word;
}

.groups-overview-grid-small .group .toolbar-buttons {
  background-color: #e0e1e2 !important;
  padding: 8px !important;
  margin: 0 !important;
  flex-grow: 0 !important;
}

.groups-overview-grid-small .group .image.icon-devices img {
  max-height: 20px !important;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}

.groups-overview-grid-small .group .image.icon-devices .label-quantity-device {
  background-color: #2a6bb7 !important;
}

.groups-overview-grid-small .group .description {
  font-weight: bold;
}
.groups-overview-grid-small .group .description .contact-list {
  font-weight: normal;
}

/* Status Groups
 ========================================================================== */
.groups-overview-grid-small .group {
  width: 280px !important;
}

/* alert = red */
.groups-overview-grid-small .group.alert {
  border: 0.9px solid #bd0000 !important;
  background-color: #bd0000 !important;
}

.groups-overview-grid-small .group.alert .link,
.groups-overview-grid-small .group.alert .link a {
  color: rgba(255, 255, 255, 0.8) !important;
}

.groups-overview-grid-small .group.alert .link:hover,
.groups-overview-grid-small .group.alert .link a:hover {
  color: rgba(255, 255, 255, 1) !important;
}

/* alert_inprogress = yellow */
.groups-overview-grid-small .group.alert_inprogress {
  border: 0.9px solid #ffcc33 !important;
  background-color: #ffcc33 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-grid-small .group.alert_inprogress .link,
.groups-overview-grid-small .group.alert_inprogress .link a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-grid-small .group.alert_inprogress .link:hover,
.groups-overview-grid-small .group.alert_inprogress .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* state: ok */
.groups-overview-grid-small .group.ok .content.description .link a {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.groups-overview-grid-small .group.ok .content.description .link a:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* Incident manager list
 ========================================================================== */

.incident-manager-titles .sort {
  box-shadow: none !important;
}

.incident-manager-titles .sort:focus,
.incident-manager-titles .button.sort:active {
  background: none !important;
}
