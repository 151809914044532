.button-add-language {
  padding: 1rem 0rem;
}

.button-without-border {
  box-shadow: none !important;
}

.label-field-multiple-languages {
  padding-left: 1rem;
  color: rgb(136, 134, 134);
}
