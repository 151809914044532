.incident-manager .filter-options {
  margin-bottom: 1rem;
}
.incident-manager .loader-container {
  margin-bottom: 2rem;
}

.incident-manager .name-commander {
  vertical-align: bottom;
}

.incident-manager .incident-header-image-title {
  display: flex;
  align-items: flex-end;
}
