/* ===========================================================================
Priority icons
=========================================================================== */
.priority .high,
.priority.high {
  color: #bd0000;
}

.priority .medium,
.priority.medium {
  color: #e74c3c;
}

.priority .low,
.priority.low {
  color: #e67e22;
}
