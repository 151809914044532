.wrapper-no-found-page {
  margin-top: -2.1rem;
}

.info-page-not-found {
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  font-size: 1rem;
  top: 50%;
  left: 20%;
  width: 80%;
  position: absolute;
  text-align: center;
}

.info-page-not-found .header {
  color: #6f6f6f;
}

.menu-page-not-found {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.menu-page-not-found .item {
  color: #6f6f6f !important;
}

.menu-page-not-found .item:hover {
  background: none !important;
  color: #000 !important;
}
