/* ==========================================================================
  Menu: View grid and list buttons + add button (by desktop)
 ========================================================================== */
.wrapper-menu-device-manager {
  display: flex;
}

.top-bar-menu .icon.button {
  color: #2a6bb7;
}

.top-bar-menu .icon.button:hover {
  background-color: #cacbcd;
  color: #093e7d;
}

.top-bar-menu .icon.button.selected {
  color: #093e7d;
  background-color: #cacbcd;
}
