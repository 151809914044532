/* ==========================================================================
  User profile
 ========================================================================== */
.user-profile-header {
  display: flex;
}

.user-profile-header .user-location {
  color: rgba(0, 0, 0, 0.4);
}
