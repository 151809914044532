.camera-overview {
  justify-content: center;
}

.camera-table-container {
  width: 100%;
  margin-top: 20px;
}

.camera-container {
  display: flex;
  justify-content: center;
}

.camera-image {
  width: 100%;
  height: 100%;
}
