/* ==========================================================================
 Button add device
 ========================================================================== */
.add-device-button-fix {
  margin-left: 10px !important;
}

.add-device-button {
  bottom: 10px;
  left: auto;
  top: auto;
  right: 0;
  position: fixed;
  max-width: 100px;
  padding: 0.5rem;
  z-index: 3;
}

.add-device-button button {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
}

.add-device-button button:active,
.add-device-button button:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2) !important;
}
