/* ==========================================================================
Footer
========================================================================== */
.footer-navigation {
  flex: none;
  align-self: flex-end;
  width: 100%;
  margin-top: 0px;
}

.footer-navigation .menu {
  background: rgba(111, 111, 111, 0.75) !important;
  text-align: center;
  border-radius: 0 !important;
  margin-top: 0px;
}

.fix-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

/* 
Using this functionality seems a bit outdated, maybe it's good to make a refactor and use flex-box
.footer-push-down {
  margin-top: 1em;
} 
*/

.footer-navigation .menu .menu-item {
  width: 25%;
  justify-content: center;
  text-align: center !important;
}

@media (max-width: 640px) {
  .footer-navigation .menu {
    width: 100%;
    flex-direction: column;
    display: block;
  }

  .footer-navigation .menu .menu-item {
    width: 100%;
    display: block;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    margin-top: 0% !important;
  }
}
